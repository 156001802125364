import React from 'react'
import Layout from "../components/organisms/layout/Layout"
import SEO from "../components/seo"
import "../styles/index.scss"
import ContentContact from "../components/organisms/ContentContact/ContentContact";
const Contactenos = () => {
  return (
    <Layout>
      <SEO
        title='Contactenos'
        description='Cold Chain Logistics'
      />
      <ContentContact />
    </Layout>
  )
}

export default Contactenos
