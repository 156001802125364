import React from 'react'
import './Banner.scss'

import {documentToReactComponents} from "@contentful/rich-text-react-renderer"
import useMobile from "../../../hooks/useMobile";

const Banner = ({description, image}) => {
  const isMobile = useMobile()

  return (
    <section className='banner'>
      <div className='banner__image'>
        <img src={image[isMobile ? 1 : 0].file.url} className='banner__image-img'
             alt={image[isMobile ? 1 : 0].description}/>
      </div>
      <div className='banner__wrapper container'>
        <div className='banner__content'>
          {documentToReactComponents(JSON.parse(description.raw))}
        </div>
      </div>
    </section>
  )
}

export default Banner
