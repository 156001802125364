import React from 'react'
import Banner from "../Banner/Banner";
import Clients from "../../molecules/Clients/Clients";
import Contact from "../Contact/Contact";
import {graphql, useStaticQuery} from "gatsby";

const ContentContact = () => {


  const { allContentfulContenidoContactenos } = useStaticQuery(
    graphql`
        query Contact_Query {
            allContentfulContenidoContactenos {
                nodes {
                    descripcionPrincipal {
                        raw
                    }
                    banner {
                        file {
                            url
                        }
                        gatsbyImageData
                        description
                    }
                }
            }
        }



    `
  )

  const contentInfo = allContentfulContenidoContactenos.nodes[0]

  return (
    <>
      <Banner
        description={contentInfo.descripcionPrincipal}
        image={contentInfo.banner}
      />
      <Contact />
      <Clients />

    </>
  )
}

export default ContentContact
